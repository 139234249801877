import React from "react";
import "../styles/Secondary.css";

const PortfolioSection = () => (
  <section className="secondary-content-section" id="portfolio-content">
    <h1>Under Construction</h1>
    {/* <h2>What You'll Find Here:</h2>
    <ul>
      <li>
        <strong>About Me:</strong> Get to know more about my background,
        interests, and what drives my passion for computer science.
      </li>
      <li>
        <strong>Experience:</strong> Discover my recent work experience or click
        on the “View Full Resume” button to see my complete resume.
      </li>
      <li>
        <strong>Skills:</strong> Explore the skills I've acquired throughout my
        academic and personal journey in Computer Science.
      </li>
      <li>
        <strong>Cryptocurrency Project:</strong> Learn about my work in
        optimizing blockchain data analysis and reducing processing times with
        multi-threading, specifically in cryptocurrency transaction tracking.
      </li>
      <li>
        <strong>Machine Learning Model:</strong> Check out my NBA Player
        Projection Model, where I've applied machine learning techniques to
        predict player performance and analyze data trends.
      </li>
      <li>
        <strong>Game Development:</strong> Dive into my C++ text-based adventure
        game, “Hunt the Wumpus,” where I've utilized object-oriented programming
        to create an engaging and interactive gaming experience.
      </li>
    </ul>
    <p>
      Feel free to explore my work to see how I apply my skills to create
      innovative solutions and engaging applications. For more details or
      inquiries, don't hesitate to reach out!
    </p> */}
  </section>
);

export default PortfolioSection;
