import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-row">
        <div className="footer-column">
          <div className="footer-header">Brandon Lee —</div>
          <div className="footer-subheader">Computer Science</div>
        </div>
        <div className="footer-column">
          <div className="footer-header">Contact —</div>
          <div className="footer-subheader">
            <a href="mailto:brandovlee@gmail.com">brandovlee@gmail.com</a>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-header">Social —</div>
          <div className="footer-subheader">
            <a
              href="https://www.instagram.com/brandovlee"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://github.com/brandovlee"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/brandon-lee-b40a472a9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
