import React from "react";
import "../styles/Secondary.css";

const NBASection = () => (
  <section className="secondary-content-section" id="nba-content">
    <h1>NBA Performance Prediction Model</h1>
    <img
      src="assets/images/tim-hart-nba.jpg"
      className="header-image-nba"
      alt="Header-Image"
    ></img>
    <p className="photo-credits">
      Photo by <a href="https://unsplash.com/@timhart0421">Tim Hart</a> on{" "}
      <a href="https://unsplash.com/">Unsplash</a>
    </p>
    <h2>The Goal</h2>
    <p>
      The goal of this project is simple: build a machine learning model that
      predicts how many points an NBA player will score on a given night with{" "}
      <i>relatively</i> high accuracy. I italicize “relatively” because, while
      the model can identify patterns and trends, the unpredictable nature of
      sports means that perfect accuracy is impossible.
    </p>
    <h2>Importance of Feature Selection</h2>
    <p>
      While machine learning models are powerful, their accuracy depends heavily
      on the <strong>quality of the data</strong>. Before building the model, I
      carefully considered which features would yield the most predictive power.
      A commonly used datapoint is the amount of points an NBA team allows
      against specific positions (guards, forwards, centers). However, I
      realized that this approach had limitations due to the positionless nature
      of modern NBA play.
    </p>
    <h2>The Problem with Position-Based Data</h2>
    <p>
      In today's NBA, a player's listed position has minimal effect on their
      playstyle and scoring tendencies. For example, both Giannis Antetokounmpo
      and Kevin Durant are categorized as forwards, yet their styles of play
      differ significantly. Giannis dominates in the paint, while Durant excels
      with mid-range and three-point shooting. Grouping these players under the
      same positional umbrella fails to capture these nuances, reducing
      prediction accuracy.
    </p>
    <h2>Introducing Player Archetypes with K-Means Clustering</h2>
    <p>
      To address this, I applied <strong>k-means clustering</strong> to group
      players by archetype rather than position. This technique segments players
      into clusters based on their playstyle and scoring tendencies. Using
      player archetypes has two key advantages:
      <ol>
        <li>
          <strong>Accuracy:</strong> Grouping players by archetype provides a
          more accurate reflection of their playstyle, improving the model's
          ability to predict how many points they will score.
        </li>
        <li>
          <strong>Data availability:</strong> Player archetypes increase the
          number of data points available for analysis. If a player only faced a
          team twice in a season, you'd be limited to two data points. By
          grouping that player with others in the same archetype, the model can
          leverage data from all players with similar tendencies, resulting in a
          larger sample size.
        </li>
      </ol>
    </p>
    <h2>Model Performance and Future Work</h2>
    <p>
      By using player archetypes, I built a model that successfully predicts
      over/under lines with <strong>60% accuracy</strong>, compared to the{" "}
      <strong>52% fair odds</strong> set by DraftKings. This project highlighted
      the critical role of feature engineering in improving model performance.
      Moving forward, I plan to refine the feature set and incorporate
      additional data sources to enhance accuracy.
    </p>
  </section>
);

export default NBASection;
