import React from "react";
import "../styles/Secondary.css";

const WumpusSection = () => (
  <section className="secondary-content-section" id="wumpus-content">
    <h1>Under Construction</h1>
    {/* <h2>Project Purpose and Goal</h2>
    <p>
      The goal of this project was to develop a C++ text-based adventure game
      called "Hunt the Wumpus." The aim was to create a fun and interactive
      gaming experience for the player using a wide variety of player-event
      interactions and gaming functionalities. The game takes place in dark
      caves, requiring the player to rely on descriptive cues when moving to an
      adjacent cave (e.g., "You hear wings flapping," indicating bats are
      nearby). The player can win either by finding the hidden gold and
      returning to the starting point or by successfully shooting and killing
      the Wumpus.
    </p>
    <h2>Problems and Thought Process</h2>
    <p>
      The most difficult part of the development process was trying to
      simultaneously handle the different game states, player inputs, and random
      events. Ultimately, I utilized an object-oriented approach that made the
      code easier to maintain. This allowed me to implement different aspects of
      the game, and then put it all together at the end.
    </p>
    <p>
      <strong>Key User-Event Interactions:</strong>
    </p>
    <ul>
      <li>
        <strong>Armor:</strong> Protects the player from the next two deadly
        encounters, acting as support for the journey.
      </li>
      <li>
        <strong>Bats:</strong> Causes the player to move in the opposite
        direction for the next five moves, confusing the player.{" "}
      </li>
      <li>
        <strong>Stalactites:</strong> Sharp icicles that have a chance of
        falling on and killing the player upon entering the room, increasing the
        game's difficulty.
      </li>
      <li>
        <strong>Finding Gold:</strong> Offers an incentive to explore the caves,
        acting as a goal for the player to achieve.
      </li>
      <li>
        <strong>Avoiding the Wumpus:</strong> Forces the player to act with
        caution to avoid the Wumpus that walks around the caves, adding a risk
        element to the game.
      </li>
      <li>
        <strong>Closed Rooms:</strong> Create dead ends and force the player to
        find alternative routes, making navigation more complex.
      </li>
      <li>
        <strong>Teleportation Rooms:</strong> Allow the player to move between
        specific rooms, introducing a strategic aspect of choosing the best
        path.
      </li>
    </ul>
    <h2>Lessons Learned & Future Work</h2>
    <p>
      This project strengthened the importance of planning and design when
      working on a software application with a lot of moving parts. I learned
      valuable lessons about object-oriented programming, including how to
      effectively use classes and objects to maintain a clean, modularized
      codebase.
    </p> */}
  </section>
);

export default WumpusSection;
