import React from "react";
import "../styles/Secondary.css";

const AboutSection = () => (
  <section className="secondary-content-section" id="about-content">
    <h1>About Me</h1>
    <div className="about-me">
      <img
        src="assets/images/headshot.png"
        className="headshot"
        alt="Brandon Lee"
      />
      <div className="about-me-text">
        <p>
          <strong>Hello!</strong> My name is Brandon Lee, I'm currently pursuing
          a Bachelor's degree in Computer Science with a focus on Artificial
          Intelligence at Oregon State University, and I'm expected to graduate
          in June 2026.
        </p>
        <p>
          <strong>Why Computer Science?</strong> Computer Science has always
          intrigued me because of the limitless possibilities that it offers.
          Whether it's building a website, training an AI model, or designing an
          interactive game, I enjoy the freedom of being able to create anything
          that I can imagine using the power of code.
        </p>
        <p>
          <strong>Get to Know Me:</strong> I stay active by weight training 5-6
          days a week, and I also enjoy playing badminton and basketball. When
          I'm not at the gym or on the court, I love spending time with my
          girlfriend, exploring new cuisines, and traveling to exciting
          destinations. So far, I've had the pleasure of visiting Canada,
          Mexico, Singapore, and Indonesia, and I can't wait to add more
          countries to my travel list!
        </p>
      </div>
    </div>
  </section>
);

export default AboutSection;
