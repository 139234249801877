import React from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";

const Navbar = () => {
  return (
    <nav>
      <div className="navbar">
        <Link to="/" className="nav-link" id="link-work">
          work
        </Link>
        <Link to="/about" className="nav-link" id="link-about">
          about
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
